import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { setOrganizationUUID } from '../../redux/reducers/auth';


const PublicRoute = ({ redirectPath = '/dashboard', children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const existingOrgUUID = useSelector((state) => state.auth.organization_uuid);

  const { organization_uuid } = useParams();

  // Update redux organization_uuid from URL params
  useEffect(() => {
    if (organization_uuid !== existingOrgUUID) {
      dispatch(setOrganizationUUID(organization_uuid));
    }
  }, [organization_uuid]);



  if (isLoggedIn) return <Navigate to={redirectPath} replace />;

  return children;
};


PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PublicRoute;
