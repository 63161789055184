import React from 'react';
import PropTypes from 'prop-types';

import { Button, DropdownButton } from 'murder-hornet-ui';


const NavBar = ({ sideNavOpen, toggleSideNav }) => (
  <nav className={`navbar z-1 d-flex justify-content-between align-items-center bg-white border-bottom p-3 ${sideNavOpen ? 'sidenav-active' : ''}`}>
    <div className='col d-flex'>
      <div className='col-auto me-2'>
        <Button
          ghost
          size='sm'
          className='sidenav-toggle-btn text-dark'
          ariaLabel={sideNavOpen ? 'Collapse' : 'Expand'}
          onClick={(event) => {
            toggleSideNav();
            if (event.currentTarget === document.activeElement) {
              event.currentTarget.blur();
            }
          }}
        >
          <span className='fas fa-bars' />
        </Button>
      </div>
      <div className='col-auto'>
        <h4 className='px-2 mb-0'>Lending Partner Portal</h4>
      </div>
    </div>

    <div className='col-auto'>
      <DropdownButton ghost size='sm'>
        <span className='fas fa-cog' aria-label='settings toggle'/>
      </DropdownButton>
    </div>
  </nav>
);


NavBar.propTypes = {
  sideNavOpen: PropTypes.bool,
  toggleSideNav: PropTypes.func,
};


export default NavBar;
