import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

import {
  Loading,
  NotFoundPage,
  UnavailablePage,
} from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// APPLICATION PIPELINE
const AllApplications = lazyWithRetry(() => import('../components/application-pipeline/AllApplications'));
const ViewApplication = lazyWithRetry(() => import('../components/application-pipeline/ViewApplication'));

// FUNDING SOURCE PIPELINE
const AllFundingSources = lazyWithRetry(() => import('../components/funding-source-pipeline/AllFundingSources'));
const ViewFundingSourceDetails = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/ViewFundingSource'));
const FundingSourcesDetail = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/Details'));
const FundingSourcesInterestRates = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/InterestRates'));
const FundingSourcePrograms = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/Programs'));

// FUNDING SOURCE PIPELINE - FS - PROGRAM
const ViewFundingSourcePrograms = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/ViewPrograms.jsx'));
const FundingSourceProgramsDetails = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/Details.jsx'));
const FundingSourceAssociatedPrograms = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/AssociatedPrograms.jsx'));
const FundingSourceInterestRates = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/InterestRates.jsx'));
const FundingSourcePricingTiers = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/PricingTiers.jsx'));
const FundingSourceFeeSchedule = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/FeeSchedule.jsx'));

// REPORTS
const KPICharts = lazyWithRetry(() => import('../components/reports/KPICharts'));
const AllReports = lazyWithRetry(() => import('../components/reports/AllReports'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));

// SYSTEM SETTINGS
const SystemSettings = lazyWithRetry(() => import('../components/settings/system-settings/SystemSettings'));
const LenderDetails = lazyWithRetry(() => import('../components/settings/system-settings/LenderDetails'));
const PaymentManagement = lazyWithRetry(() => import('../components/settings/system-settings/PaymentManagement'));
const AllLocations = lazyWithRetry(() => import('../components/settings/system-settings/AllLocations'));
const AllUsers = lazyWithRetry(() => import('../components/settings/system-settings/AllUsers'));
const ViewUser = lazyWithRetry(() => import('../components/settings/system-settings/ViewUser'));
const InviteUser = lazyWithRetry(() => import('../components/settings/system-settings/InviteUser'));
const AllPermissionGroups = lazyWithRetry(() => import('../components/settings/system-settings/AllPermissionGroups'));
const MessageCenterTemplates = lazyWithRetry(() => import('../components/settings/system-settings/MessageCenterTemplates'));
const ActivityLogTemplates = lazyWithRetry(() => import('../components/settings/system-settings/ActivityLogTemplates'));

// HELP
const Help = lazyWithRetry(() => import('../components/help/Help'));

// RELEASE NOTES
const ReleaseNotes = lazyWithRetry(() => import('../components/settings/help/release-notes/ReleaseNotes'));
const ViewReleaseNote = lazyWithRetry(() => import('../components/settings/help/release-notes/ViewReleaseNote'));

// HELP TICKETS
// const HelpTickets = lazyWithRetry(() => import('../components/help/help-tickets/HelpTickets'));
// const CreateHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/CreateHelpTicket'));
// const ViewHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/ViewHelpTicket'));
// const HelpTicketDetails = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketDetails'));
// const HelpTicketCommunications = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketCommunications'));


// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createHistory();


const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login/:organization_uuid' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:organization_uuid/:token_uuid' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session/:organization_uuid' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password/:organization_uuid' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:organization_uuid/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* APPLICATIONS */}
              <Route path='/applications' element={<PrivateRoute perform={[
                'lender:application:read-all',
                'lender:application:read-team',
                'lender:application:read-assigned',
              ]}><AllApplications /></PrivateRoute>} />
              <Route path='/applications/:application_uuid/:layout?' element={<PrivateRoute perform={[
                'lender:application:read-all',
                'lender:application:read-team',
                'lender:application:read-assigned',
              ]}><ViewApplication /></PrivateRoute>} />

              {/* FUNDING SOURCE PIPELINE*/}
              <Route path='/funding-source-pipeline' element={<PrivateRoute perform={['lender:fs-pipeline:read-all']}><AllFundingSources /></PrivateRoute>} />
              <Route path='/funding-source-pipeline/:funding_source_uuid' element={<PrivateRoute perform={['lender:fs-pipeline:read-all']}><ViewFundingSourceDetails /></PrivateRoute>} />

              {/* REPORTS */}
              <Route path='/reports' element={<PrivateRoute perform={['lender:report:read-all-reports']}><AllReports /></PrivateRoute>} />
              <Route path='/charts' element={<PrivateRoute perform={['lender:report:read-kpi-charts']}><KPICharts /></PrivateRoute>} />

              {/* FUNDING SOURCE PIPELINE*/}
              <Route path='/funding-sources' element={<PrivateRoute><AllFundingSources /></PrivateRoute>} />
              <Route path='/funding-sources/:funding_source_uuid' element={<PrivateRoute><ViewFundingSourceDetails /></PrivateRoute>} >
                <Route path='details' element={<FundingSourcesDetail />} />
                <Route path='interest-rates' element={<FundingSourcesInterestRates />} />
                <Route path='programs' element={<FundingSourcePrograms />} />
              </Route>
              <Route path='/funding-sources/:funding_source_uuid/programs/:program_uuid' element={<PrivateRoute><ViewFundingSourcePrograms /></PrivateRoute>}>
                <Route path='details' element={<FundingSourceProgramsDetails />} />
                <Route path='associate-programs' element={<FundingSourceAssociatedPrograms />} />
                <Route path='interest-rates' element={<FundingSourceInterestRates />} />
                <Route path='pricing-tiers' element={<FundingSourcePricingTiers />} />
                <Route path='fee-schedule' element={<FundingSourceFeeSchedule />} />
              </Route>

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>

              {/* SYSTEM SETTINGS */}
              <Route path='/system-settings'>
                <Route index element={<PrivateRoute perform={['lender:lender-settings:read']}><SystemSettings /></PrivateRoute>} />
                <Route path='lender-details' element={<PrivateRoute perform={['lender:lender-settings:read']}><LenderDetails /></PrivateRoute>} />
                <Route path='payment-management' element={<PrivateRoute perform={['lender:lender-settings:read']}><PaymentManagement /></PrivateRoute>} />
                <Route path='locations' element={<PrivateRoute perform={['lender:location-settings:read']}><AllLocations /></PrivateRoute>} />
                <Route path='users' element={<PrivateRoute perform={['lender:user-settings:read']}><AllUsers /></PrivateRoute>} />
                <Route path='users/invite' element={<PrivateRoute perform={['lender:user-settings:write']}><InviteUser /></PrivateRoute>} />
                <Route path='users/:user_uuid' element={<PrivateRoute perform={['lender:user-settings:read']}><ViewUser /></PrivateRoute>} />
                <Route path='permissions' element={<PrivateRoute perform={['lender:user-settings:write']}><AllPermissionGroups /></PrivateRoute>} />
                <Route path='message-center-templates' element={<PrivateRoute perform={['lender:communication-templates-settings:read']}><MessageCenterTemplates /></PrivateRoute>} />
                <Route path='activity-log-templates' element={<PrivateRoute perform={['lender:activity-log-note-templates-settings:read']}><ActivityLogTemplates /></PrivateRoute>} />
              </Route>

              {/* HELP */}
              <Route path='help'>
                <Route index element={<Help />} />

                {/* RELEASE NOTES */}
                <Route path='release-notes' element={<PrivateRoute perform={['lender:release-notes:read']}><ReleaseNotes /></PrivateRoute>} />
                <Route path='release-notes/:release_note_uuid' element={<PrivateRoute perform={['lender:release-notes:read']}><ViewReleaseNote /></PrivateRoute>} />

                {/* HELP TICKETS */}
                {/* <Route path='help-tickets' element={<PrivateRoute><HelpTickets /></PrivateRoute>} />
                <Route path='help-tickets/new' element={<PrivateRoute perform='lender:special:test'><CreateHelpTicket /></PrivateRoute>} />
                <Route path='help-tickets/:help_ticket_uuid' element={<PrivateRoute><ViewHelpTicket /></PrivateRoute>}>
                  <Route path='details' element={<HelpTicketDetails />} />
                  <Route path='communications' element={<HelpTicketCommunications />} />
                </Route> */}
              </Route>;

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<UnavailablePage />} />

              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
      </div>
    </ScrollToTop>
  </CustomRouter>
);


AppRouter.propTypes = {
};


export default AppRouter;
